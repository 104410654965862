import React from "react"
import Navbar from "../components/navbar"

export default function PhoneProject() {
  return (
    <>
      <Navbar></Navbar>
      <h1>This is phone project!</h1>
    </>
  )
}
